import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { isUrl } from '../../utils/links.utils';
import { distanceInWordsToNow } from 'date-fns';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import Button from '../ui/Button';
import Chip from '../ui/Chip';
import useMediaQuery from 'use-media-query-hook';

const additionalStyles = {
	imgContainer: {
		position: 'absolute',
		background: 'rgba(255, 255, 255, 0.6)',
		width: '100%',
		height: '100%'
	},
	img: {
		width: '100%',
		objectFit: 'cover',
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
		zIndex: -1,
		border: '1px solid #eaeaea'
	},
	title: {
		backgroundColor: 'rgba(255, 255, 255, 0.7)'
	}
};

const useStyles = makeStyles({
	root: {
		height: 150,
		display: 'block',
		textDecoration: 'none',
		color: 'black',
		cursor: 'default'
	},
	container: {
		flexGrow: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		padding: '16px 8px',
		zIndex: 1
	},
	linkContainer: ({ link }) => ({
		display: 'flex',
		margin: '16px 8px',
		background: '#97C4DB',
		borderRadius: 10,
		transition:
			'background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease',
		cursor: isUrl(link) ? 'pointer' : 'default',
		willChange: 'background, box-shadow, transform',
		'&:hover': {
			background: isUrl(link) ? 'rgb(195, 205, 220);' : '#97C4DB',
			boxShadow: '0px 11px 20px -10px rgba(0,0,0,0.66)',
			transform: 'translateY(-7px)'
		},
		height: 150
	}),
	dateActionContainer: {
		display: 'flex'
	},
	date: {
		flexGrow: 0
	},
	action: {
		flexGrow: 0
	},
	title: ({ isTabletOrHigher }) => {
		// const add = isTabletOrHigher ? {} : additionalStyles;
		return {
			// ...add.title
		};
	},
	imgContainer: ({ isTabletOrHigher }) => {
		const add = isTabletOrHigher ? {} : additionalStyles;
		return {
			width: 200,
			height: 150,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			...add.imgContainer,
			'& img': {
				width: 200,
				height: 150,
				borderTopLeftRadius: 10,
				borderBottomLeftRadius: 10,
				...add.img
			}
		};
	},
	chipContainer: {
		bottom: 0,
		top: 0,
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1
	}
});

const Link = React.memo(({ link, style, onDelete, onOpenPopover }) => {
	const isTabletOrHigher = useMediaQuery('(min-width: 768px)');
	const classes = useStyles({ ...link, isTabletOrHigher });
	return (
		<a
			style={style}
			className={classes.root}
			href={link.link}
			target="_blank"
			rel="noopener noreferrer">
			<div className={classes.linkContainer}>
				{link.thumbnailUrl && (
					<div className={classes.imgContainer}>
						<img src={link.thumbnailUrl} alt="Thumbnail" />
					</div>
				)}
				<div className={classes.container}>
					<h4 className={classes.title}>
						{link.link && link.link.length > 0 ? link.link : '-'}
					</h4>
					<div className={classes.dateActionContainer}>
						<p className={classes.date}>
							{link.date
								? distanceInWordsToNow(new Date(parseInt(link.date)), {
										addSuffix: true
								  })
								: ''}
						</p>
						<div className={classes.chipContainer}>
							{link.tags.map((tg) => (
								<Chip
									key={tg}
									title={tg}
									color="info"
									textColor="#fff"
									size="small"
								/>
							))}
						</div>
						<div className={classes.action}>
							<Button
								icon={<PencilIcon />}
								onClick={(e) => {
									e.preventDefault();
									onOpenPopover(link);
								}}
								width={0}
								color="#000"
							/>
							<Button
								icon={<DeleteIcon />}
								onClick={(e) => {
									e.preventDefault();
									onDelete(link);
								}}
								width={0}
								color="#000"
							/>
						</div>
					</div>
				</div>
			</div>
		</a>
	);
});

Link.propTypes = {
	link: PropTypes.object.isRequired
};

Link.defaultProps = {
	link: {
		link: '',
		date: '',
		thumbnailUrl: ''
	},
	onDelete: () => {},
	onClosePopover: () => {}
};

export default Link;
