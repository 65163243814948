import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		padding: '0 4px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%'
	}
});

const Row = (props) => {
	const c = useStyles();
	return <div className={c.root}>{props.children}</div>;
};

export default Row;
