import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import Spinner from '../ui/Spinner';
import { useTransition, animated } from 'react-spring';
import TagsPopover from '../TagsPopover/TagsPopover';
import Link from '../Link/Link';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		position: 'relative'
	},
	loadingContainer: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%'
	}
});

const Messages = ({
	messages,
	height,
	onClick,
	loading,
	onUpdate,
	onDelete
}) => {
	const classes = useStyles();
	const transitions = useTransition(loading, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 }
	});
	const [message, setMessage] = useState(null);

	const Row = ({ index, style }) => (
		<Link
			link={messages[messages.length - 1 - index]}
			style={{ ...style, top: style.top + 75 }}
			onClick={onClick}
			onDelete={onDelete}
			onOpenPopover={(msg) => setMessage(msg)}
		/>
	);

	return (
		<div className={classes.root}>
			<InfiniteLoader
				isItemLoaded={(index) => index < messages.length}
				itemCount={messages.length}>
				{({ onItemsRendered, ref }) => (
					<List
						height={height}
						itemCount={messages.length}
						itemSize={160}
						onItemsRendered={onItemsRendered}
						ref={ref}>
						{Row}
					</List>
				)}
			</InfiniteLoader>
			{transitions.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							className={classes.loadingContainer}
							key={key}
							style={props}>
							<Spinner />
						</animated.div>
					)
			)}
			{message && (
				<TagsPopover
					link={message}
					onClose={(l) => {
						setMessage(null);
						onUpdate(l);
					}}
					open={Boolean(message)}
				/>
			)}
		</div>
	);
};

Messages.propTypes = {
	messages: PropTypes.array
};

Messages.defaultProps = {
	messages: [],
	width: 500
	// height: 500
};

export default Messages;
