export const getColor = (color) => {
	switch (color) {
		case 'primary':
			return '#0093E9';
		case 'secondary':
			return '#0093E9';
		case 'info':
			return '#0093E9';
		case 'success':
			return '#0093E9';
		case 'warning':
			return '#0093E9';
		case 'danger':
			return '#ff0844';
		case 'transparent':
			return 'transparent';
		default:
			return color;
	}
};

export const getBackgroundImage = (color) => {
	switch (color) {
		case 'primary':
			return 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)';
		case 'secondary':
			return 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)';
		case 'info':
			return 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)';
		case 'success':
			return 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)';
		case 'warning':
			return 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)';
		case 'danger':
			return 'linear-gradient(to top, #ff0844 0%, #ffb199 100%)';
		case 'transparent':
			return 'transparent';
		default:
			return color;
	}
};

export const validateColor = (props, propName, componentName) => {
	if (
		props[propName] === 'info' ||
		props[propName] === 'success' ||
		props[propName] === 'warning' ||
		props[propName] === 'danger' ||
		props[propName] === 'primary' ||
		props[propName] === 'secondary'
	) {
	} else {
		if (/#.../.test(props[propName]) || /#....../.test(props[propName])) {
			return;
		}
		if (/rgb\(*\)/.test(props[propName])) {
			return;
		}
		if (/rgba\(*\)/.test(props[propName])) {
			return;
		}
		return new Error('Not a valid color.');
	}
};

export const getSize = (size) => {
	switch (size) {
		case 'small':
			return {
				minWidth: 56,
				height: 48
			};
		case 'mini':
			return {
				minWidth: 48,
				height: 36
			};
		case 'large':
			return {
				minWidth: 72,
				height: 64
			};
		default:
			return {
				minWidth: 64,
				height: 56
			};
	}
};
