import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Links from '../../components/Links/Links';
import { links$, deleteLink$, updateLink$ } from '../../services/Api.service';
import { tap, switchMap, first } from 'rxjs/operators';
import { useObservable } from 'rxjs-hooks';
import { Subject } from 'rxjs';
import { connect } from 'react-redux';
import {
	linksBatchAdd,
	linksDelete,
	linksUpdate
} from '../../store/actions/links.actions';
import { getAccessToken } from '../../services/Login.service';
import FullScreen from '../../components/ui/FullScreen';
import Spinner from '../../components/ui/Spinner';
import LinkIcon from 'mdi-react/LinkIcon';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		display: 'flex',
		position: 'relative'
	},
	noLinksLabelContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#6e8ba0'
	}
});

const pageSubs = new Subject(1);
const page$ = pageSubs.asObservable();

const mapStateToProps = (state) => ({
	pagedLinks: state.links
});

const mapDispatchToProps = (dispatch) => ({
	addLinksBatch: (links) => dispatch(linksBatchAdd(links)),
	deleteLink: (link) => dispatch(linksDelete(link)),
	updateLink: (link) => dispatch(linksUpdate(link))
});

const LinksComponent = ({
	pagedLinks,
	addLinksBatch,
	deleteLink,
	updateLink,
	height
}) => {
	const token = getAccessToken();
	const { links, page } = pagedLinks;
	const classes = useStyles();
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	useObservable(
		() =>
			page$.pipe(
				switchMap((page) => links$(token, page)),
				tap((res) => {
					setTotal(res.total);
					addLinksBatch({
						page: res.page,
						links: res.docs
					});
					setLoading(false);
				})
			),
		[]
	);

	useEffect(() => {
		if (page === 0) {
			setLoading(true);
			pageSubs.next(page + 1);
		}
	}, [page]);

	const loadMore = () => {
		setLoading(true);
		pageSubs.next(page + 1);
	};

	const onLinkClick = (link) => {
		const cleanLink = link.match(/^http[s]?:\/\//) ? link : `//${link}`;
		window.open(cleanLink, '_blank');
	};

	const onLinkDelete = (link) => {
		deleteLink(link);
		deleteLink$(token, link).subscribe(() => {});
	};

	const onLinkUpdate = (link) => {
		updateLink(link);
		updateLink$(token, link)
			.pipe(first())
			.subscribe();
	};

	return (
		<div className={classes.root}>
			<Links
				links={links}
				total={total}
				loadMore={loadMore}
				height={height}
				onClick={onLinkClick}
				onDelete={onLinkDelete}
				onUpdate={onLinkUpdate}
			/>
			{!loading && links.length === 0 && (
				<FullScreen>
					<div className={classes.noLinksLabelContainer}>
						<LinkIcon size="3em" />
						<h2>No saved links. Add some!</h2>
					</div>
				</FullScreen>
			)}
			{loading && (
				<FullScreen>
					<Spinner />
				</FullScreen>
			)}
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(LinksComponent);
