import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { makeStyles } from '@material-ui/styles';
import Link from '../Link';
import TagsPopover from '../TagsPopover/TagsPopover';

const useStyles = makeStyles({
	root: {
		flexGrow: 1
	},
	container: {
		borderRadius: 6
	}
});

const Links = ({
	links,
	total,
	loadMore,
	height,
	onClick,
	onDelete,
	onUpdate
}) => {
	const classes = useStyles();
	const [link, setLink] = useState(null);

	const Row = ({ index, style }) => {
		return (
			<Link
				link={links[index]}
				style={style}
				onClick={onClick}
				onDelete={onDelete}
				onOpenPopover={(link) => {
					setLink(link);
				}}
			/>
		);
	};

	return (
		<div className={classes.root}>
			<InfiniteLoader
				isItemLoaded={(index) => index < links.length}
				itemCount={total}
				loadMoreItems={loadMore}>
				{({ onItemsRendered, ref }) => (
					<List
						height={height}
						itemCount={links.length}
						itemSize={165}
						onItemsRendered={onItemsRendered}
						ref={ref}>
						{Row}
					</List>
				)}
			</InfiniteLoader>
			{link && (
				<TagsPopover
					link={link}
					onClose={(l) => {
						setLink(null);
						onUpdate(l);
					}}
					open={Boolean(link)}
				/>
			)}
		</div>
	);
};

Links.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			link: PropTypes.string,
			date: PropTypes.number
		})
	),
	total: PropTypes.number,
	loadMore: PropTypes.func
};

Links.defaultProps = {
	loadMore: () => {}
};

export default Links;
