import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TextBox from '../ui/TextBox';
import Chip from '../ui/Chip/Chip';
import Modal from 'react-modal';
import { makeStyles } from '@material-ui/styles';
import Button from '../ui/Button';
import PlusIcon from 'mdi-react/PlusIcon';

const useStyles = makeStyles({
	root: {
		width: 300,
		backgroundColor: '#fff',
		borderRadius: 10,
		paddingLeft: 12,
		paddingRight: 12,
		paddingBottom: 12,
		paddingTop: 8
	},
	title: {
		marginTop: 0
	},
	chipsContainer: {},
	inputContainer: {
		display: 'flex'
	},
	textBox: {
		border: '2px solid #565656',
		flexGrow: 1
	}
});

const modalStyle = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.6)'
	}
};

const TagsPopover = ({ link, open, onClose }) => {
	const c = useStyles();
	const tagRef = useRef(null);
	const [updatedTags, setUpdatedTags] = useState(link.tags);

	const addTag = () => {
		const updTags = [...updatedTags, tagRef.current.value];
		setUpdatedTags(updTags.filter((t, index) => updTags.indexOf(t) >= index));
		tagRef.current.value = '';
	};

	const removeTag = (tag) => {
		setUpdatedTags(updatedTags.filter((t) => t !== tag));
	};

	return (
		<Modal
			isOpen={open}
			onRequestClose={() => onClose({ ...link, tags: updatedTags })}
			style={modalStyle}
			ariaHideApp={false}>
			<div className={c.root}>
				<h2 className={c.title}>Tags</h2>
				<div>
					<div className={c.inputContainer}>
						<TextBox
							className={c.textBox}
							inputRef={tagRef}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									addTag();
								}
							}}
						/>
						<Button
							color="#000"
							icon={<PlusIcon />}
							width={0}
							onClick={addTag}
						/>
					</div>
					<div>
						{updatedTags.map((tg) => (
							<Chip
								key={tg}
								title={tg}
								color="info"
								textColor="#fff"
								onClose={() => removeTag(tg)}
							/>
						))}
					</div>
				</div>
			</div>
		</Modal>
	);
};

TagsPopover.propTypes = {
	tags: PropTypes.arrayOf([PropTypes.string])
};

TagsPopover.defaultProps = {
	tags: []
};

export default TagsPopover;
