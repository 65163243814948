import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LinksMobileComponent from '../LinksMobileComponent';
import MessagesComponent from '../MessagesComponent';
import PropTypes from 'prop-types';
import history from '../../services/History';
import AppLayout from '../AppLayout';
import Button from '../../components/ui/Button';
import AppBar from '../../components/ui/AppBar';
import Row from '../../components/ui/Row/Row';
import { makeStyles } from '@material-ui/styles';
import LogoutVariantIcon from 'mdi-react/LogoutVariantIcon';
import { logout, isTokenValid } from '../../services/Login.service';
import useMediaQuery from 'use-media-query-hook';
import { Transition, animated } from 'react-spring/renderprops';
import ReactResizeDetector from 'react-resize-detector';
import CubeOutlineIcon from 'mdi-react/CubeOutlineIcon';

const useStyles = makeStyles({
	messagesContainer: {
		flexDirection: 'column',
		flexGrow: 1
	},
	route: {
		position: 'absolute',
		width: '100%',
		display: 'flex'
	},
	icon: {
		color: '#1e83e8'
	}
});

/**
 * Component that will display the Mobile layout.
 *
 * @param {Object} pusher - is the client that contain methods for notify other possible tabs
 * for the logged in user.
 * @param {Object} location - is the current location of the browsing history (e.g. /m/main)
 */
const MobileLayoutComponent = ({ pusher, location }) => {
	// Obtain if the size of the screen corresponds to a tablet or desktop.
	const isTabletOrHigher = useMediaQuery('(min-width: 768px)');
	// Style of the components
	const classes = useStyles({ isTabletOrHigher });
	// Obtains the state of the height of the screen.
	const [height, setHeight] = useState(0);

	// Function that will logout the user.
	const onLogOut = () => {
		logout();
	};

	// Redirect to login if user is not logged in, or token is not valid.
	if (!isTokenValid()) {
		history.push('/login');
	}

	// Resize function callback that will be called when the application changes the
	// height of the screen.
	const onResize = (width, height) => {
		setHeight(height);
	};

	// Returns the component to render.
	return (
		// This is the main layout of the application. This contains the menu bar.
		<AppLayout
			isLoggedIn={true}
			activeAction={
				location.pathname.split('/').length === 3
					? location.pathname.split('/')[2]
					: ''
			}>
			<AppBar>
				<Row>
					<div className={classes.icon}>
						<CubeOutlineIcon size="2em" />
					</div>
					<div>
						<Button
							title="Log out"
							icon={<LogoutVariantIcon />}
							color="#000"
							width={0}
							onClick={onLogOut}
						/>
					</div>
				</Row>
			</AppBar>
			<div className={classes.messagesContainer}>
				<ReactResizeDetector handleHeight onResize={onResize} />
				<Transition
					native
					items={location}
					keys={(location) => location.pathname}
					from={(location) => ({
						transform: `translateX(${
							location.pathname === '/m/messages' ? '-100%' : '100%'
						})`
					})}
					enter={{ transform: 'translateX(0)' }}
					leave={{
						transform: `translateX(${
							location.pathname === '/m/messages' ? '100%' : '-100%'
						})`
					}}>
					{(loc, state) => (style) => (
						<div className={classes.messagesContainer}>
							<Switch location={state === 'update' ? location : loc}>
								<Route
									path="/m/messages"
									render={() => (
										<animated.div style={style} className={classes.route}>
											<MessagesComponent pusher={pusher} height={height} />
										</animated.div>
									)}
								/>
								<Route
									path="/m/links"
									render={() => (
										<animated.div style={style} className={classes.route}>
											<LinksMobileComponent pusher={pusher} height={height} />
										</animated.div>
									)}
								/>
								<Route
									exact
									path="/m"
									render={() => <Redirect to="/m/messages" />}
								/>
							</Switch>
						</div>
					)}
				</Transition>
			</div>
		</AppLayout>
	);
};

MobileLayoutComponent.propTypes = {
	pusher: PropTypes.object
};

export default MobileLayoutComponent;
