import React from 'react';
import './App.css';
import PusherService from './services/Push.service';
import LoginComponent from './containers/LoginComponent';
import { Router, Route, Redirect } from 'react-router-dom';
import history from './services/History';
import { Provider } from 'react-redux';
import store from './store/store';
import RegisterComponent from './containers/RegisterComponent';
import { isTokenValid } from './services/Login.service';
import LayoutSelector from './containers/LayoutSelector/LayoutSelector';
import { AnimatedSwitch } from 'react-router-transition';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		width: '100%',
		height: '100%',
		'& > div': {
			position: 'absolute',
			width: '100%',
			height: '100%'
		}
	},
	route: {
		position: 'absolute',
		width: '100%',
		height: '100%'
	}
});

function mapStyles(styles) {
	return {
		opacity: styles.opacity
	};
}

const bounceTransition = {
	atEnter: {
		opacity: 0,
		scale: -100
	},
	atLeave: {
		opacity: 0,
		scale: 100
	},
	atActive: {
		opacity: 1,
		scale: 0
	}
};

// For sending notification to other web pages.
const pusher = PusherService();

// Main application.
// It provides a store (a local database structure that holds information for the session).
// and a Router object (which can support navigation between components).
const App = () => {
	const classes = useStyles();
	return (
		// Provides a store for temporal storage of the application.
		<Provider store={store}>
			{/* Gets a router object which contain the browsing history for the app */}
			<Router history={history}>
				{/* This will animate the transitions between routes */}
				<AnimatedSwitch
					atEnter={bounceTransition.atEnter}
					atLeave={bounceTransition.atLeave}
					atActive={bounceTransition.atActive}
					mapStyles={mapStyles}
					className={classes.root}>
					{/* Path to login web page */}
					<Route
						path="/login"
						render={() => (
							<div className={classes.route}>
								<LoginComponent pusher={pusher} />
							</div>
						)}
					/>
					{/* Path to register page */}
					<Route
						path="/register"
						render={() => (
							<div className={classes.route}>
								<RegisterComponent pusher={pusher} />
							</div>
						)}
					/>
					{/* This will hold the main content of the app for logged in users. */}
					<Route
						path="/m"
						render={(props) => (
							<div className={classes.route}>
								{/* Component that will select the layout (mobile or desktop) */}
								<LayoutSelector pusher={pusher} {...props} />
							</div>
						)}
					/>
					{/* Default or root route will redirect to the main page when logged in or
					to login page if its not logged in. */}
					<Route
						exact
						path="/"
						render={() =>
							isTokenValid() ? <Redirect to="/m" /> : <Redirect to="/login" />
						}
					/>
				</AnimatedSwitch>
			</Router>
		</Provider>
	);
};

export default App;
