import React, { useState, useRef, useEffect } from 'react';
import Messages from '../../components/Messages';
import { filter, first } from 'rxjs/operators';
import { makeStyles } from '@material-ui/styles';
import TextBox from '../../components/ui/TextBox';
import Button from '../../components/ui/Button';
import AppLayout from '../AppLayout/AppLayout';
import SendIcon from 'mdi-react/SendIcon';
import ReactResizeDetector from 'react-resize-detector';
import LinksComponent from '../LinksComponent';
import AppBar from '../../components/ui/AppBar';
import { connect } from 'react-redux';
import {
	messagesAdd,
	messagesDelete,
	messagesUpdate
} from '../../store/actions/messages.actions';
import LogoutVariantIcon from 'mdi-react/LogoutVariantIcon';
import Row from '../../components/ui/Row/Row';
import { logout, getAccessToken, getId } from '../../services/Login.service';
import useMediaQuery from 'use-media-query-hook';
import CubeOutlineIcon from 'mdi-react/CubeOutlineIcon';
import FullScreen from '../../components/ui/FullScreen';
import { updateLink$, deleteLink$ } from '../../services/Api.service';

const useStyles = makeStyles({
	root: (props) => ({
		display: 'flex',
		height: '100%',
		flexDirection: props.isTabletOrHigher ? 'row' : 'column'
	}),
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1
	},
	messagesContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1
	},
	sendMessageContainer: {
		display: 'flex',
		backgroundColor: '#fff',
		alignItems: 'center',
		boxShadow: '0px 5px 15px 5px rgba(0,0,0,0.3)',
		position: 'absolute',
		left: 0,
		right: 0,
		margin: 12,
		borderRadius: 10,
		zIndex: 1000
	},
	innerSendMessageContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		padding: '0 8px'
	},
	listContainer: {
		height: '100%'
	},
	holder: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute'
	},
	linksContainer: {
		display: 'flex',
		flexGrow: 1
	},
	newMessagesLabelContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#6e8ba0'
	},
	icon: {
		color: '#1e83e8'
	}
});

const mapStateToProps = (state) => ({
	messages: state.messages
});

const mapDispatchToProps = (dispatch) => ({
	addMessage: (message) => dispatch(messagesAdd(message)),
	deleteMessage: (message) => dispatch(messagesDelete(message)),
	updateMessage: (message) => dispatch(messagesUpdate(message))
});

let subs;
let deleteSubs;

const DesktopLayoutComponent = ({
	pusher,
	messages,
	addMessage,
	deleteMessage,
	updateMessage
}) => {
	const isTabletOrHigher = useMediaQuery('(min-width: 768px)');
	const token = getAccessToken();
	const inputRef = useRef(null);
	const [height, setHeight] = useState(1);
	const [linksHeight, setLinksHeight] = useState(1);
	const classes = useStyles({ isTabletOrHigher });
	const [loading, setLoading] = useState(false);

	const onButtonClick = async () => {
		try {
			setLoading(true);
			await pusher.sendMessage(token, {
				message: inputRef.current.value
			});
			setLoading(false);
			inputRef.current.value = '';
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const onResize = (width, height) => {
		setHeight(height);
	};

	const onLinksResize = (width, height) => {
		setLinksHeight(height);
	};

	useEffect(() => {
		if (!pusher.isSubscribed()) {
			const id = getId();
			pusher.subscribe(id);
		}
		subs = pusher.messages$
			.pipe(filter((msg) => msg !== null))
			.subscribe((msg) => {
				addMessage(msg);
			});
		deleteSubs = pusher.deleteMessages$
			.pipe(filter((msg) => msg !== null))
			.subscribe(onMessageDelete);
		return () => {
			subs.unsubscribe();
			deleteSubs.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onLinkClick = (link) => {
		const cleanLink = link.match(/^http[s]?:\/\//) ? link : `//${link}`;
		window.open(cleanLink, '_blank');
	};

	const onKeyPress = async (e) => {
		if (e.key === 'Enter') {
			await onButtonClick();
		}
	};

	const onLogOut = () => {
		logout();
	};

	const onMessageDelete = (msg) => {
		deleteMessage(msg);
		deleteLink$(token, msg)
			.pipe(first())
			.subscribe();
	};

	const onMessageUpdate = (message) => {
		updateMessage(message);
		updateLink$(token, message)
			.pipe(first())
			.subscribe();
	};

	return (
		<AppLayout isLoggedIn>
			<div className={classes.root}>
				<div className={classes.messagesContainer}>
					<AppBar>
						<Row>
							<div className={classes.icon}>
								<CubeOutlineIcon size="2em" />
							</div>
							<div>
								<Button
									title="Log out"
									icon={<LogoutVariantIcon />}
									color="#000"
									width={0}
									onClick={onLogOut}
								/>
							</div>
						</Row>
						<div className={classes.sendMessageContainer}>
							<div className={classes.innerSendMessageContainer}>
								<TextBox
									inputRef={inputRef}
									type="text"
									placeholder="Share link..."
									style={{ width: 200, flexGrow: 1 }}
									onKeyPress={onKeyPress}
									outline={false}
								/>
								<Button
									onClick={onButtonClick}
									icon={<SendIcon />}
									width={0}
									hasBackground={false}
									color="#000"
								/>
							</div>
						</div>
					</AppBar>
					<div className={classes.container}>
						<div className={classes.listContainer}>
							<ReactResizeDetector handleHeight onResize={onResize} />
							<Messages
								messages={messages}
								height={height}
								onClick={onLinkClick}
								loading={loading}
								onUpdate={onMessageUpdate}
								onDelete={async (msg) => await pusher.deleteMessage(token, msg)}
							/>
						</div>
						{messages.length === 0 && (
							<FullScreen style={{ alignSelf: 'center' }}>
								<div className={classes.newMessagesLabelContainer}>
									<CubeOutlineIcon size="3em" />
									<h2>New links will appear here</h2>
								</div>
							</FullScreen>
						)}
					</div>
				</div>
				<div className={classes.linksContainer}>
					<ReactResizeDetector handleHeight onResize={onLinksResize} />
					<LinksComponent height={linksHeight} />
				</div>
			</div>
		</AppLayout>
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopLayoutComponent);
