import PusherClient from 'pusher-js';
import { Subject } from 'rxjs';
import { getAccessToken, logout } from './Login.service';

const onMessageSubject = new Subject();
const onMessageDeleteSubject = new Subject();
const messages$ = onMessageSubject.asObservable();
const deleteMessages$ = onMessageDeleteSubject.asObservable();
const url = process.env.REACT_APP_BASE_URL;

export default () => {
	let subscribed = false;
	// Register
	const client = new PusherClient('909ff542494cf60e3c4d', {
		cluster: 'us3',
		forceTLS: true,
		authEndpoint: `${url}auth/pusher`
	});

	const sendMessage = async (token, msg) => {
		const updatedMsg = { ...msg };
		try {
			const response = await fetch(`${url}messages/create`, {
				method: 'POST',
				mode: 'cors',
				body: JSON.stringify(updatedMsg),
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				credentials: 'include'
			});

			// Do a POST to wake up server
			setTimeout(async () => {
				await fetch(`${url}messages/create`, {
					method: 'POST',
					mode: 'cors',
					body: JSON.stringify({ message: '' }),
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`
					},
					credentials: 'include'
				});
			}, 100);

			return await response.json();
		} catch (err) {
			await logout();
		}
	};

	const deleteMessage = async (token, msg) => {
		const updatedMsg = { ...msg };
		try {
			const response = await fetch(`${url}messages/delete`, {
				method: 'POST',
				mode: 'cors',
				body: JSON.stringify(updatedMsg),
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				credentials: 'include'
			});

			// Do a POST to wake up server
			setTimeout(async () => {
				await fetch(`${url}messages/create`, {
					method: 'POST',
					mode: 'cors',
					body: JSON.stringify({ message: '' }),
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`
					},
					credentials: 'include'
				});
			}, 100);

			return await response.json();
		} catch (err) {
			await logout();
		}
	};

	const subscribe = async (id) => {
		subscribed = true;
		const channel = client.subscribe(`private-${id}`);

		channel.bind('message-event', (data) => {
			if (data.link !== '') {
				onMessageSubject.next(data);
				if (navigator.serviceWorker.controller) {
					navigator.serviceWorker.controller.postMessage(data);
				}
			}
		});

		channel.bind('message-delete-event', (data) => {
			if (data.link !== '') {
				onMessageDeleteSubject.next(data);
			}
		});

		const token = getAccessToken();
		sendMessage(token, { message: '' });
	};

	const isSubscribed = () => subscribed;

	return {
		messages$,
		deleteMessages$,
		sendMessage,
		deleteMessage,
		subscribe,
		isSubscribed
	};
};
