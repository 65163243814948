import { MESSAGES_ADD, MESSAGES_RESET, MESSAGES_DELETE, MESSAGES_UPDATE } from '../actions/messages.actions';

const initialState = [];

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case MESSAGES_ADD:
			return [...state, payload];
		case MESSAGES_RESET:
			return initialState;
		case MESSAGES_DELETE:
			return state.filter((l) => l.id !== payload.id);
		case MESSAGES_UPDATE:
			return state.map((l) => {
					if (l.id !== payload.id) {
						return l;
					}
					return {
						...l,
						...payload
					};
				}
			);
		default:
			return state;
	}
};
