import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		// backgroundColor: '#eaeaea',
		// boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
		zIndex: 5,
		minHeight: 64,
		paddingLeft: 16,
		paddingRight: 16,
		position: 'relative'
	}
});

const AppBar = (props) => {
	const classes = useStyles();

	return <div className={classes.root}>{props.children}</div>;
};

AppBar.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default AppBar;
