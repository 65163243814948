import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import history from './History';

const url = process.env.REACT_APP_BASE_URL;

export const links$ = (token, page) => {
	return ajax({
		url: `${url}links/all`,
		method: 'POST',
		crossDomain: true,
		mode: 'cors',
		body: {
			page
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		credentials: 'include'
	}).pipe(
		map((res) => res.response),
		catchError((err) => {
			console.error(err);
			sessionStorage.clear();
			history.push('/login');
		})
	);
};

export const deleteLink$ = (token, link) => {
	return ajax({
		url: `${url}links/delete`,
		method: 'POST',
		crossDomain: true,
		mode: 'cors',
		body: {
			id: link.id
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		credentials: 'include'
	}).pipe(
		map((res) => res.response),
		catchError((err) => {
			console.error(err);
			sessionStorage.clear();
			history.push('/login');
		})
	);
};

export const addTags$ = (token, link, tags) => {
	return ajax({
		url: `${url}links/update`,
		method: 'POST',
		crossDomain: true,
		mode: 'cors',
		body: {
			id: link.id,
			tags
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		credentials: 'include'
	}).pipe(
		map((res) => res.response),
		catchError((err) => {
			console.error(err);
			sessionStorage.clear();
			history.push('/login');
		})
	);
};

export const updateLink$ = (token, link) => {
	return ajax({
		url: `${url}links/update`,
		method: 'POST',
		crossDomain: true,
		mode: 'cors',
		body: link,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		credentials: 'include'
	}).pipe(
		map((res) => res.response),
		catchError((err) => {
			console.error(err);
			sessionStorage.clear();
			history.push('/login');
		})
	);
};
