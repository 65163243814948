import React from 'react';
import useMediaQuery from 'use-media-query-hook';
import MobileLayoutComponent from '../MobileLayoutComponent/MobileLayoutComponent';
import DesktopLayoutComponent from '../DesktopLayoutComponent/DesktopLayoutComponent';

/**
 * Component that will select between mobile or desktop layout.
 *
 * Both layouts contain different options to show.
 *
 * @param {Object} props - is the props object
 */
const LayoutSelector = (props) => {
	const isTabletOrHigher = useMediaQuery('(min-width: 768px)');
	if (!isTabletOrHigher) {
		return <MobileLayoutComponent {...props} />;
	} else {
		return <DesktopLayoutComponent {...props} />;
	}
};

export default LayoutSelector;
