import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import TextBox from '../../components/ui/TextBox';
import Button from '../../components/ui/Button';
import {
	login$,
	saveDataAccess,
	isTokenValid
} from '../../services/Login.service';
import history from '../../services/History';
import AppLayout from '../AppLayout/AppLayout';
import AppBar from '../../components/ui/AppBar';
import { catchError } from 'rxjs/operators';
import Spinner from '../../components/ui/Spinner';
import FullScreen from '../../components/ui/FullScreen';
import { useTransition, animated } from 'react-spring';
import useMediaQuery from 'use-media-query-hook';
import CubeOutlineIcon from 'mdi-react/CubeOutlineIcon';
import Row from '../../components/ui/Row/Row';
import Separator from '../../components/ui/Separator/Separator';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	formContainer: (props) => ({
		display: 'flex',
		flexDirection: 'column',
		'& *': {
			margin: '4px 0'
		},
		flexGrow: 1,
		justifyContent: 'center',
		width: props.isTabletOrHigher ? '400px' : 'auto',
		margin: props.isTabletOrHigher ? '0 auto' : '24px 12px'
	}),
	form: {
		display: 'flex',
		'& *': {
			flexGrow: 1
		}
	},
	registerContainer: (props) => ({
		display: 'flex',
		flexDirection: 'column',
		padding: '24px 12px',
		textAlign: 'center',
		margin: props.isTabletOrHigher ? '0 auto' : '24px 0px',
		width: props.isTabletOrHigher ? '400px' : 'auto'
	}),
	errorLabel: {
		color: 'red',
		textAlign: 'center'
	},
	loadingContainer: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%'
	},
	icon: {
		color: '#1e83e8',
		marginRight: 8
	}
});

const LoginComponent = ({ pusher }) => {
	const isTabletOrHigher = useMediaQuery('(min-width: 601px)');
	const classes = useStyles({ isTabletOrHigher });
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const transitions = useTransition(loading, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 }
	});

	const onLoginClick = () => {
		const email = emailRef.current.value;
		const password = passwordRef.current.value;
		setLoading(true);
		login$(email, password)
			.pipe(
				catchError(() => {
					setError(true);
					setLoading(false);
				})
			)
			.subscribe(async (jwt) => {
				await saveDataAccess(jwt);
				window.location.href = '/m';
			});
	};

	const onRegisterClick = () => {
		history.push('/register');
	};

	useEffect(() => {
		if (isTokenValid()) {
			history.push('/m');
		}
	}, []);

	return (
		<AppLayout pusher={pusher} isLoggedIn={false}>
			<div className={classes.root}>
				<AppBar>
					<Row>
						<div className={classes.icon}>
							<CubeOutlineIcon size="2em" />
						</div>
						<h3>Login</h3>
						<Separator />
					</Row>
				</AppBar>
				<div className={classes.formContainer}>
					<div className={classes.form}>
						<span className={classes.errorLabel}>
							{error ? 'Invalid credentials. Try again.' : ''}
						</span>
					</div>
					<div className={classes.form}>
						<span>Email</span>
						<TextBox type="text" inputRef={emailRef} />
					</div>
					<div className={classes.form}>
						<span>Password</span>
						<TextBox type="password" inputRef={passwordRef} />
					</div>
					<Button title="Login" onClick={onLoginClick} background="#485665" />
				</div>
				<div className={classes.registerContainer}>
					<h4>Don't have an account yet?</h4>
					<Button
						title="Register"
						background="#62BCE0"
						onClick={onRegisterClick}
					/>
				</div>
				{transitions.map(
					({ item, key, props }) =>
						item && (
							<animated.div
								className={classes.loadingContainer}
								key={key}
								style={props}>
								<FullScreen>
									<Spinner />
								</FullScreen>
							</animated.div>
						)
				)}
			</div>
		</AppLayout>
	);
};

export default LoginComponent;
