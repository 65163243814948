import React from 'react';
import './Spinner.css';

const Circle = (props) => (
	<svg className="svg" viewBox="0 0 66 66" {...props}>
		<circle
			fill="none"
			cx={33}
			cy={33}
			r={28}
			strokeWidth="8"
			strokeLinecap="round"
		/>
	</svg>
);

const Spinner = (props) => (
	<div className="spinner">
		<Circle />
		<Circle />
		<Circle />
		<Circle />
	</div>
);

export default Spinner;
