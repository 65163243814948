export const LINKS_BATCH_ADD = 'LINKS_BATCH_ADD';
export const LINKS_RESET = 'LINKS_RESET';
export const LINKS_DELETE = 'LINKS_DELETE';
export const LINKS_UPDATE = 'LINKS_UPDATE';

export const linksBatchAdd = (payload) => ({
	type: LINKS_BATCH_ADD,
	payload
});

export const linksReset = (payload) => ({
	type: LINKS_RESET,
	payload
});

export const linksDelete = (payload) => ({
	type: LINKS_DELETE,
	payload
});

export const linksUpdate = (payload) => ({
	type: LINKS_UPDATE,
	payload
});
