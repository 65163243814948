import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%'
	}
});

const FullScreen = (props) => {
	const c = useStyles();
	const { children, ...rest } = props;
	return (
		<div className={c.root} {...rest}>
			{children}
		</div>
	);
};

export default FullScreen;
