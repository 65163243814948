import {
	LINKS_BATCH_ADD,
	LINKS_RESET,
	LINKS_DELETE,
	LINKS_UPDATE
} from '../actions/links.actions';

const initialState = {
	page: 0,
	links: []
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case LINKS_BATCH_ADD:
			return {
				page: payload.page,
				links: [...state.links, ...payload.links]
			};
		case LINKS_RESET:
			return initialState;
		case LINKS_DELETE:
			return {
				...state,
				links: state.links.filter((l) => l.id !== payload.id)
			};
		case LINKS_UPDATE:
			return {
				...state,
				links: state.links.map((l) => {
					if (l.id !== payload.id) {
						return l;
					}
					return {
						...l,
						...payload
					};
				})
			};
		default:
			return state;
	}
};
