import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import CubeOutlineIcon from 'mdi-react/CubeOutlineIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import history from '../../services/History';
import useMediaQuery from 'use-media-query-hook';
const BottomActionButton = lazy(() =>
	import('../../components/ui/BottomActionButton/BottomActionButton')
);

const useStyles = makeStyles({
	root: {
		width: '100%',
		height: '100vh',
		display: 'flex'
	},
	rootMobile: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	bottomBar: {
		flexGrow: 0,
		minHeight: 64,
		backgroundImage:
			'linear-gradient(to right top, #0b84e1, #1076cd, #1168ba, #105ba7, #0d4e94)',
		display: 'flex',
		flexDirection: 'row',
		borderRadius: '15px 15px 0 0'
	}
});

const AppLayout = ({ children, isLoggedIn, activeAction }) => {
	const isMobile = useMediaQuery('(max-width: 400px)');
	const isTablet = useMediaQuery('(min-width: 401px) and (max-width: 640px)');
	const isDesktop = useMediaQuery('(min-width: 641px) and (max-width: 1024px)');
	const isLargeDesktop = useMediaQuery('(min-width: 1025px)');
	const classes = useStyles();

	const onMessagesButtonClick = () => {
		history.push('/m/messages');
	};

	const onLinkButtonClick = () => {
		history.push('/m/links');
	};

	return (
		<>
			{(isDesktop || isLargeDesktop) && (
				<div className={classes.root}>
					<div className={classes.container}>{children}</div>
				</div>
			)}
			{(isMobile || isTablet) && (
				<div className={classes.rootMobile}>
					<div className={classes.container}>{children}</div>
					{isLoggedIn && (
						<div className={classes.bottomBar}>
							<Suspense fallback={<div>Loading...</div>}>
								<BottomActionButton
									icon={<CubeOutlineIcon />}
									text="Messages"
									onClick={onMessagesButtonClick}
									active={activeAction === 'messages'}
								/>
								<BottomActionButton
									icon={<LinkIcon />}
									text="Links"
									onClick={onLinkButtonClick}
									active={activeAction === 'links'}
								/>
							</Suspense>
						</div>
					)}
				</div>
			)}
		</>
	);
};

AppLayout.propTypes = {
	isLoggedIn: PropTypes.bool,
	activeAction: PropTypes.string
};

AppLayout.defaultProps = {
	isLoggedIn: false,
	activeAction: ''
};

export default AppLayout;
