import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		flexGrow: 1
	}
});

const Separator = (props) => {
	const classes = useStyles();
	return <div className={classes.root} />;
};

export default Separator;
