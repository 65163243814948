import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from 'mdi-react/CloseIcon';
import { getColor } from '../utils/colors';

const parseSize = {
	small: { height: 16, fontSize: 10 },
	normal: { height: 32, fontSize: 14 },
	large: { height: 48, fontSize: 18 }
};

const useStyles = makeStyles({
	root: ({ focused, color, size }) => ({
		backgroundColor: focused
			? color
				? getColor(color)
				: '#efefef'
			: color
			? getColor(color)
			: '#f2f2f2',
		borderRadius: 16,
		width: 'auto',
		height: size ? parseSize[size].height : 32,
		display: 'inline-flex',
		margin: 4,
		alignItems: 'center'
	}),
	title: ({ textColor, size }) => ({
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
		color: textColor,
		fontSize: size ? parseSize[size].fontSize : 14
	}),
	closeIcon: {
		borderRadius: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		width: 32,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#fff',
		cursor: 'pointer'
	}
});

const Chip = ({ title, onClose, focused, color, textColor, size, ...rest }) => {
	const c = useStyles({ focused, color, textColor, size });
	return (
		<div className={c.root} {...rest}>
			<span className={c.title}>{title}</span>
			{onClose && (
				<span className={c.closeIcon} onClick={onClose}>
					<CloseIcon size={14} />
				</span>
			)}
		</div>
	);
};

Chip.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string,
	onClose: PropTypes.func,
	textColor: PropTypes.string,
	size: PropTypes.oneOf(['small', 'normal', 'large'])
};

Chip.defaultProps = {
	title: '',
	textColor: '#000',
	size: 'normal'
};

export default Chip;
