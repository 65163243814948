import React from 'react';
import LinksComponent from '../LinksComponent';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	linksContainer: {
		flexGrow: 1
	}
});

const LinksMobileComponent = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<LinksComponent {...props} />
		</div>
	);
};

export default LinksMobileComponent;
