export const MESSAGES_ADD = 'MESSAGES_ADD';
export const MESSAGES_RESET = 'MESSAGES_RESET';
export const MESSAGES_UPDATE = 'MESSAGES_UPDATE';
export const MESSAGES_DELETE = 'MESSAGES_DELETE';

export const messagesAdd = (payload) => ({
	type: MESSAGES_ADD,
	payload
});

export const messagesReset = (payload) => ({
	type: MESSAGES_RESET,
	payload
});

export const messagesUpdate = (payload) => ({
	type: MESSAGES_UPDATE,
	payload
});

export const messagesDelete = (payload) => ({
	type: MESSAGES_DELETE,
	payload
});

