import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: (props) => ({
		padding: props.paddings ? '12px 16px' : 0,
		border: '0px solid #eaeaea',
		borderRadius: 6,
		minWidth: props.width >= 0 ? props.width : 96,
		fontSize: 15,
		background: props.background ? props.background : 'transparent',
		cursor: 'pointer',
		color: props.color ? props.color : '#fff',
		'&:hover': {
			filter: 'brightness(115%)'
		},
		transition: 'filter 0.3s ease'
	})
});

const Button = (props) => {
	const classes = useStyles(props);
	const { hasBackground, paddings, ...rest } = props;
	return (
		<button className={classes.root} {...rest}>
			{props.icon ? props.icon : props.title}
		</button>
	);
};

Button.propTypes = {
	paddings: PropTypes.bool,
	width: PropTypes.number,
	hasBackground: PropTypes.bool,
	color: PropTypes.string,
	background: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Button.defaultProps = {
	paddings: true,
	width: 96,
	hasBackground: true
};

export default Button;
