import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import history from './History';
import store from '../store/store';
import { messagesReset } from '../store/actions/messages.actions';
import { linksReset } from '../store/actions/links.actions';

const url = process.env.REACT_APP_BASE_URL;

export const login$ = (email, password) => {
	return ajax({
		url: `${url}auth/login`,
		method: 'POST',
		crossDomain: true,
		mode: 'cors',
		body: {
			email,
			password
		}
	}).pipe(
		map((res) => {
			if (res.response === undefined) {
				return res;
			}
			return res.response;
		})
	);
};

export const register$ = (name, email, password) => {
	return ajax({
		url: `${url}auth/register`,
		method: 'POST',
		crossDomain: true,
		mode: 'cors',
		body: {
			name,
			email,
			password
		}
	}).pipe(map((res) => res.response));
};

/**
 * Saves token for logged in user.
 * @param {jwt} jwt - is the token from the login or register.
 */
export const saveDataAccess = ({ accessToken, id, expiresIn }) =>
	new Promise((resolve, reject) => {
		saveAccessToken(accessToken);
		saveId(id);
		saveExpiresAt(expiresIn);
		resolve();
	});

export const clearSession = () =>
	new Promise((resolve, reject) => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('id');
		localStorage.removeItem('expiresAt');
		resolve();
	});

/**
 * Gest the identifier of the user.
 */
export const getId = () => {
	if (isTokenValid()) {
		return localStorage.getItem('id');
	} else {
		logout();
		return null;
	}
};

export const saveId = (id) => {
	localStorage.setItem('id', id);
};

export const saveAccessToken = (token) => {
	localStorage.setItem('accessToken', token);
};

/**
 * Obtains the access token for operations.
 */
export const getAccessToken = () => {
	if (isTokenValid()) {
		return localStorage.getItem('accessToken');
	} else {
		// Clean if not valid
		logout();
		return null;
	}
};

export const saveExpiresAt = (duration) => {
	const durationInt = parseInt(duration) * 1000;
	const time = new Date().getTime();
	localStorage.setItem('expiresAt', time + durationInt);
};

export const getExpiresAt = () => {
	return localStorage.getItem('expiresAt');
};

/**
 * Checks if the token is valid.
 * @returns true if valid, otherwise false.
 */
export const isTokenValid = () => {
	const expiresAtStr = getExpiresAt();
	if (!expiresAtStr) {
		localStorage.removeItem('expiresAt');
		return false;
	}
	const expiresAt = parseInt(expiresAtStr);
	const time = new Date().getTime();
	return expiresAt > time;
};

export const logout = async () => {
	await clearSession();
	store.dispatch(messagesReset());
	store.dispatch(linksReset());
	window.location.href = '/login';
};

export const goToAndRefresh = (to) => {
	history.push(to);
	window.location.reload();
};
