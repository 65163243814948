import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: (props) => ({
		padding: '8px 12px',
		border: `${props.outline ? 1 : 0}px solid #eaeaea`,
		borderRadius: 6,
		minWidth: 96,
		fontSize: 15
	})
});

const TextBox = (props) => {
	const classes = useStyles(props);
	const { inputRef, outline, className, ...rest } = props;
	return (
		<input
			className={clsx(classes.root, className)}
			type={props.type}
			ref={inputRef}
			{...rest}
		/>
	);
};

TextBox.propTypes = {
	outline: PropTypes.bool
};

TextBox.defaultProps = {
	outline: true
};

export default TextBox;
